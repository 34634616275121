import Helmet from 'react-helmet';
import { Link } from 'react-router-dom';
import { Urls } from '../../routes';
import APIError from '../../utils/errors';
// import OtherListings from '../otherListings';
import Image404 from './404.png';

export default function NotFound({ error }: { error: APIError }) {
  return (
    <>
      <Meta404 />
      <div className="mt-4 text-center container">
        <img src={Image404} alt="not found" width="450px" />
        <h4 className="text-secondary mb-3">
          Oops! Page not found. Go{' '}
          <Link to={Urls.LANDING_PAGE} className="underline">
            home
          </Link>
        </h4>

        {/* <OtherListings
          type="Popular"
          id={0}
          leftAlign
          title="Check out our popular listings"
        /> */}
      </div>
    </>
  );
}

export const Meta404 = () => (
  <>
    <Helmet>
      {/* <meta name="robots" content="noindex" /> */}
      <meta name="prerender-status-code" content="404" />
    </Helmet>
  </>
);
