import qs from 'qs';
import APIError from './errors';
import config from './config';
import { toSnakeCase, toCamelCase } from './';

const isDev = process.env.NODE_ENV === 'development';
const useLocalProxy = process.env.REACT_APP_USE_LOCAL_PROXY;

// Stolen from https://docs.djangoproject.com/en/3.2/ref/csrf/#ajax
function getCookie(name: string) {
  let cookieValue = null;
  if (document.cookie && document.cookie !== '') {
    const cookies = document.cookie.split(';');
    for (let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i].trim();
      // Does this cookie string begin with the name we want?
      if (cookie.substring(0, name.length + 1) === name + '=') {
        cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
        break;
      }
    }
  }
  return cookieValue;
}

const prodCookie = document.querySelector<HTMLInputElement>(
  '[name=csrfmiddlewaretoken]'
)?.value;

class ApiRequest {
  isInternal;
  constructor(isInternal: boolean = false) {
    this.isInternal = isInternal;
  }
  static returnStatusAndJson = <T>(response: Response): Promise<T> => {
    if (response.statusText === 'No Content') return Promise.resolve<any>({});
    return response
      .json()
      .then((json) => {
        if (!response.ok) {
          return Promise.reject(new APIError(response.status, json));
        }
        return toCamelCase(json) as T;
      })
      .catch((e) => Promise.reject(e));
  };

  getHeaders(isForm = false): Record<string, string> {
    const headers: Record<string, string> = {
      Accept: 'application/json'
    };
    if (!isForm) headers['Content-Type'] = 'application/json';

    if (this.isInternal) {
      if (isDev) headers['X-CSRFToken'] = getCookie('csrftoken') || '';
      else if (prodCookie) headers['X-CSRFToken'] = prodCookie;
    }

    return headers;
  }

  getConfig(
    method: RequestInit['method'],
    data?: object | FormData,
    useSnakeCase = true,
    isFormData = false
  ): RequestInit {
    const opts: RequestInit = {
      credentials: isDev && !useLocalProxy ? 'include' : 'same-origin',
      method: method,
      headers: this.getHeaders(isFormData)
    };

    if (data)
      opts.body = JSON.stringify(useSnakeCase ? toSnakeCase(data) : data);

    return opts;
  }

  getUrl(endpoint: string): string {
    if (!this.isInternal && endpoint.startsWith('http')) {
      throw new Error(`Using internal request instance for an external url`);
    }

    if (endpoint.startsWith('http')) return endpoint;

    if (this.isInternal && useLocalProxy) {
      return `/api/${endpoint}`;
    }

    return `${config.baseUrl}/api/${endpoint}`;
  }

  get<T>(endpoint: string, query?: object, useSnakeCase = true) {
    let url = this.getUrl(endpoint);
    if (query)
      url +=
        '?' +
        qs.stringify(useSnakeCase ? toSnakeCase(query) : query, {
          strictNullHandling: true,
          skipNulls: true,
          arrayFormat: 'repeat'
        });
    return fetch(url, this.getConfig('get')).then<T>(
      ApiRequest.returnStatusAndJson
    );
  }

  post<T>(endpoint: string, data?: object, useSnakeCase = true) {
    return this.update<T>(endpoint, data, 'post', useSnakeCase);
  }

  postFormData<T>(endpoint: string, data?: FormData, useSnakeCase = true) {
    return this.update<T>(endpoint, data, 'post', useSnakeCase, true);
  }

  put<T>(endpoint: string, data: object, useSnakeCase = true) {
    return this.update<T>(endpoint, data, 'put', useSnakeCase);
  }

  patch<T>(endpoint: string, data: object, useSnakeCase = true) {
    return this.update<T>(endpoint, data, 'PATCH', useSnakeCase);
  }

  update<T>(
    endpoint: string,
    data: object | undefined,
    type: 'post' | 'put' | 'PATCH' = 'post', // PATCH needs to be uppercased
    useSnakeCase = true,
    isFormData = false
  ) {
    return fetch(
      this.getUrl(endpoint),
      this.getConfig(type, data, useSnakeCase, isFormData)
    ).then<T>(ApiRequest.returnStatusAndJson);
  }

  delete<T>(endpoint: string, data?: object) {
    return fetch(this.getUrl(endpoint), this.getConfig('delete', data)).then<T>(
      ApiRequest.returnStatusAndJson
    );
  }
}

export const rawRequest = (url: string, config: RequestInit) =>
  fetch(url, config);

export const externalRequest = new ApiRequest();

export default new ApiRequest(true);
