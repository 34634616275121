import { TListingSnippet } from './../features/search/types';
/**
 * Monkey patch analytics to disable on dev
 */

const noop = () => {};

const keys = [
  'page',
  'track',
  'trackClick',
  'identify',
  'alias',
  'reset'
] as const;

const useAnalytics =
  process.env.NODE_ENV === 'production' &&
  !window.location.search.includes('preview');
const analytics = window.analytics ?? {};
const isLoaded = !!window.analytics;

// We try to use Segment's semantic events as much as possible -
export const TRACK_EVENTS = {
  // below are remapped to recombee's  in segment
  LISTING_VIEWED: 'Product Viewed',
  LISTING_BOOKMARKED: 'Product Added to Wishlist',
  LISTING_INTERESTED: 'Product Added',
  LISTING_WAITLISTED: 'Product Added to Wishlist',
  LISTING_SHARED: 'Product Shared',
  LISTING_FILTER: 'Product List Filtered',

  // these below should ideally be remapped in recombee's segment destination, but it seems the destination only
  // allows remapping "Bookmark,Cart Addition,Detail View,Rating,View Portion" (View Portion does not work either). So we have to use the actual semantic event
  // names (https://docs.recombee.com/segment.html#track) which segment will then forward to Recombee - and it works
  LISTING_UNINTERESTED: 'Product Removed',
  LISTING_DEWAITLISTED: 'Product Removed from Wishlist',

  // These currently don't work for recombee (even though they should), so we're doing it on the backend
  LISTING_UNBOOKMARKED: 'Product Removed from Wishlist',
  LISTING_VIDEO_PLAYED: 'Video Playback Started',

  // others, non-recombee
  LISTING_CONTACT_US_CLICKED: 'LISTING_CONTACT_US_CLICKED',
  LISTING_PAYMENT_MODAL: 'Cart Viewed',
  LISTING_PAY_CLICKED: 'Checkout Started',
  LISTING_PAID: 'Checkout Step Completed',
  LISTING_REVIEWED: 'Product Reviewed',
  SUBSCRIBE: 'Subscribe',
  RECOMMENDATION_CLICKED: 'RECOMMENDATION_CLICKED',
  SHOW_EDIT_INSPECTION_SCHEDULE: 'SHOW_EDIT_INSPECTION_SCHEDULE',
  SHOW_BOOK_INSPECTION_SCHEDULE: 'SHOW_BOOK_INSPECTION_SCHEDULE',
  SAVE_LISTING_PREFERENCE: 'SAVE_LISTING_PREFERENCE',
  REMOVE_LISTING_PREFERENCE: 'REMOVE_LISTING_PREFERENCE',
  CONTACT_US_CLICKED: 'Contact',
  INSPECTION_VIDEO_PLAYED: 'INSPECTION_VIDEO_PLAYED',
  LISTING_AMOUNT_BREAKDOWN_VIEWED: 'LISTING_AMOUNT_BREAKDOWN_VIEWED',

  POOL_ACTIVITY: 'POOL_ACTIVITY',
  POOL_REVOKE: 'POOL_REVOKE',
  ALLOW_POOL_PHOTO: 'ALLOW_POOL_PHOTO'
};

function hijackAnalytics() {
  window.analytics = keys.reduce((a, b) => {
    // @ts-ignore
    a[b] = noop;
    return a;
  }, analytics);
}
function patchAnalytics() {
  for (let key of keys) {
    const func = window.analytics[key];

    // @ts-ignore
    analytics[key] = (
      ...args: Parameters<typeof func>
    ): ReturnType<typeof func> | void => {
      if (!useAnalytics) return noop();

      // @ts-ignore
      return func(...args);
    };
  }
}

if (!isLoaded) {
  // e.g if the script not included in the html in dev mode
  hijackAnalytics();
} else {
  patchAnalytics();

  // repatch with new changes when analytics ready. If we don't do this, the consumers
  // of this module will not have the updated analytics
  window.analytics.ready(function () {
    patchAnalytics();
  });
}

type TSourceMiddlewareArgs = {
  payload: {
    obj: {
      properties?: {
        listingId?: number | string;
      };
    };
  };
  integrations: object[];
  next: Function;
};

function StringifyListingId({
  payload,
  integrations,
  next
}: TSourceMiddlewareArgs) {
  // Recombee needs string ids. we really should be doing this in a destination middleware, but it doesn't work with Recombee dest
  if (payload.obj.properties?.listingId) {
    payload.obj.properties.listingId = String(payload.obj.properties.listingId);
  }
  next(payload);
}

if (useAnalytics) {
  // This isn't working, so let's rely on source middleware, sadly
  // analytics.addDestinationMiddleware('Recombee AI', [StringifyListingId])

  analytics.addSourceMiddleware(StringifyListingId);
}

export const listingAnalyticsData = (
  listing: TListingSnippet,
  useSemanticProduct = false
) => {
  const semanticData = {
    listingId: listing.id,

    // needed by segment's semantic event
    price: listing.amount,
    product_id: listing.id,
    quantity: 1,
    title: listing.title
  };

  const data = {
    ...semanticData,
    acceptRoommate: listing.acceptRoommate,
    amount: listing.amount,
    listingType: listing.listingType,
    ref: listing.reference,
    ...(listing.acceptRoommate
      ? {
          roommateAmount: listing.roommateAmount
        }
      : {}),
    ...(useSemanticProduct
      ? {
          products: [semanticData]
        }
      : undefined)
  };

  return data;
};

export default analytics;
