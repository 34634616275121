import React, { useEffect, useState, useContext, useRef } from 'react';
import { Loader } from '@googlemaps/js-api-loader';
import config from '../../utils/config';
import { reportError } from '../../utils/errors';

type ConstructableGMap = {
  new (element: HTMLElement, options?: {}): google.maps.Map;
};

type TGMapType = {
  Map: ConstructableGMap;
};

type TMapState = {
  loading: boolean;
  map: null | TGMapType;
  error: null | Error;
};

const defaultState: TMapState = { loading: true, map: null, error: null }; // just to make TS happy
const mapContext = React.createContext(defaultState);

export const MapProvider = ({ children }: { children: React.ReactNode }) => {
  const [state, setState] = useState(defaultState);
  const loadAttempts = useRef(0);

  useEffect(() => {
    async function loadMap() {
      loadAttempts.current++;
      try {
        await new Loader({
          apiKey: config.googlePlacesAPIKey!,
          ...{ libraries: ['places'], region: 'NG' }
        }).load();
        setState({ loading: false, error: null, map: window.google.maps });
      } catch (error: any) {
        reportError(error);
        setState((prev) => ({ loading: false, error, map: prev.map }));

        if (loadAttempts.current <= 3) {
          const timeoutId = setTimeout(function () {
            loadMap();
            clearTimeout(timeoutId);
          }, loadAttempts.current * 1000);
        }
      }
    }
    loadMap();
  }, []);

  return <mapContext.Provider value={state}>{children}</mapContext.Provider>;
};

export default function useGoogleMap() {
  return useContext(mapContext);
}
