import React from 'react';
import Accordion from 'react-bootstrap/Accordion';
import Card from 'react-bootstrap/Card';
import { reportError } from '../../utils/errors';

const isProd = process.env.NODE_ENV === 'production';

type TPanelProps = {
  message?: string;
  error?: Error | null;
  retry?: () => void;
  retryCount: number;
};

type TErrorBoundaryProps = Omit<TPanelProps, 'retryCount'> & {
  element?: React.ComponentType;
};

const MAX_RETRIES = 3;

const ErrorPanel = ({ message, error, retry, retryCount }: TPanelProps) => {
  return (
    <Accordion defaultActiveKey="1">
      <Card>
        <Card.Header className="py-2">
          <Accordion.Toggle eventKey="0" as="span">
            {message}
          </Accordion.Toggle>
          {retryCount < MAX_RETRIES ? (
            <button
              className="btn btn-sm btn-secondary ml-2 pull-right"
              onClick={retry}
              data-testid="retry"
              aria-label="Retry"
            >
              Click here to retry
            </button>
          ) : (
            <span className="ml-1">
              Please try later. If this persists, reload the page or{' '}
              <a className="underline" href="mailto:support@cribstack.com">
                contact us
              </a>
            </span>
          )}
        </Card.Header>
        <Accordion.Collapse eventKey="0">
          <Card.Body>
            {!isProd && error ? (
              <pre>{error.toString()}</pre>
            ) : (
              "If this doesn't solve your problem, kindly refresh the page"
            )}
          </Card.Body>
        </Accordion.Collapse>
      </Card>
    </Accordion>
  );
};

type TErrorInfo = {
  componentStack: string;
};

class ErrorBoundary extends React.Component<TErrorBoundaryProps> {
  state = { error: null };
  retryCount = 0;

  static getDerivedStateFromError(error: Error) {
    // Update state so the next render will show the fallback UI.
    return { error };
  }

  componentDidCatch(error: Error, info: TErrorInfo) {
    reportError(error, info.componentStack);
  }

  retry = () => {
    if (this.retryCount < 3) {
      this.setState({ error: null });
      this.retryCount++;
    }
  };

  render() {
    const { element: Element, message = 'Something went wrong.' } = this.props;
    const { error } = this.state;
    if (error)
      return Element ? (
        <Element>{message}</Element>
      ) : (
        <ErrorPanel
          message={message}
          error={error}
          retry={this.retry}
          retryCount={this.retryCount}
        />
      );

    return this.props.children;
  }
}

export default ErrorBoundary;
