import { geocodeByPlaceId } from 'react-google-places-autocomplete';
import { InAppError } from '../../utils/errors';

import request from '../../utils/request';
import {
  TSearchParams,
  IAPIFilterResponse,
  IListingsAPIResponse
} from './types';

export const getListingFilters = () =>
  request.get('listings/filters/') as Promise<IAPIFilterResponse>;

const search = (query: Omit<TSearchParams, 'location'> & { coord?: string }) =>
  request.get<IListingsAPIResponse>('listings/', query);

export const getListings = (
  query: TSearchParams
): Promise<IListingsAPIResponse> => {
  // return search(query)
  return new Promise(async (resolve, reject) => {
    let copy = { ...query };

    // both location and placeId needed together
    if ((copy.placeId || copy.location) && !(copy.placeId && copy.location)) {
      return reject(
        new InAppError('Location is incorrect. Check your filter and retry')
      );
    }

    if (copy.placeId && copy.location) {
      let isIncorrectLocation = false;
      let errorMessage = 'Cannot find filtered location';

      try {
        const geoData = await geocodeByPlaceId(copy.placeId);
        if (geoData.length) {
          const data = geoData[0];
          copy.coord = `${data.geometry.location.lat()}-${data.geometry.location.lng()}`;

          // This would happen on page load if user copies incorrect placeId/location
          // TODO: consider how to implement this
          // if (data.formatted_address !== copy.location) {
          //   const fuzzyScore = fuzzyMatchAddress(geoData, copy.location)
          //   if (!fuzzyScore || fuzzyScore < 0.8) {
          //     console.log('fuzzy failed')
          //     throw new Error('Cannot find location')
          //   }
          // }
        } else {
          isIncorrectLocation = true;
          throw new Error(errorMessage);
        }
      } catch (error) {
        if (!isIncorrectLocation) {
          // other kind of unknown error
          errorMessage = `${errorMessage}. Try searching again`;
        }
        return reject(new InAppError(errorMessage));
      }
    }
    delete copy.location;
    delete copy.placeId;

    copy.filterType = copy.listingType;
    // Gradually replacing this with filterType until we refactor
    delete copy.listingType;

    try {
      resolve(await search(copy));
    } catch (e) {
      reject(e);
    }
  });
  // geocodeByPlaceId(value as string)
  // return Promise.resolve('')
  // if (!query.placeId) return search(query)

  // try {

  // } catch (e) {

  // }
};
