import React, { Suspense } from 'react';
import { Redirect, RouteComponentProps } from 'react-router-dom';
import config from '../utils/config';
import ErrorBoundary from '../components/errorBoundary';
import Spinner from '../components/spinner';
import { TRoute } from './types';

import * as pages from './pages';

interface IProps extends RouteComponentProps {}
interface IDashboardProps extends RouteComponentProps {
  routes: TRoute[];
}

export type TListingDetailsUrlParams = {
  listingTypeFacet: string;
  id: number;
  slug: string;
};
export interface IListingDetailRouteProps
  extends RouteComponentProps<
    Omit<TListingDetailsUrlParams, 'id'> & {
      id: string;
    },
    any,
    { from?: string }
  > {}

// https://reactrouter.com/web/example/route-config
export const Urls = {
  LANDING_PAGE: '/',
  LISTINGS_PAGE: '/search',
  ALERTS: '/alerts',
  LISTING_DETAILS_PAGE: '/listings/:listingTypeFacet/:id/:slug',
  DASHBOARD_PAGES: '/dashboard',
  // SERVICES: '/dashboard/services',
  PROFILE_PAGE: '/dashboard/account/profile',
  VERIFICATION_PAGE: '/dashboard/account/verification',
  GUARANTOR_PAGE: '/dashboard/account/guarantor',
  KYC_PAGE: '/dashboard/account/kyc',
  CHANGE_PASSWORD: `${config.baseUrl}/auth/password/change`,
  FLATMATE_PREFERENCES: '/dashboard/preferences/flatmates',
  LISTING_PREFERENCES: '/dashboard/preferences/listings',
  // TOPIC_PREFERENCES: '/dashboard/preferences/topics',
  BOOKMARKS: '/dashboard/saved/bookmarks',
  WAITLISTS: '/dashboard/saved/waitlists',
  INTERESTS: '/dashboard/saved/interests',
  PAYMENTS: '/dashboard/history/payments',
  INSPECTIONS: '/dashboard/history/inspections',
  INSPECTION_DETAIL: '/dashboard/history/inspections/:id/:action',
  POOLS: '/dashboard/history/pools',
  POOL_DETAIL: '/dashboard/history/pools/:ref',
  TENURES: '/dashboard/history/tenures',
  TENURES_DETAIL: '/dashboard/history/tenures/:reference/:action',
  // Hosts Dashboard Routes
  PARTNER_PAGES: '/partner',
  PARTNER_PROFILE_PAGE: '/partner/account/profile',
  PARTNER_LISTINGS_PAGE: '/partner/history/listings',
  PARTNER_TENURES_PAGE: '/partner/history/tenures',
  PARTNER_INSPECTIONS_PAGE: '/partner/history/inspections',
  PARTNER_PAYMENTS_PAGE: '/partner/history/payments',
  LOGIN: `${config.baseUrl}/auth/login/`,
  SIGNUP: `${config.baseUrl}/auth/signup/`,
  LOGOUT: `${config.baseUrl}/auth/logout/`,
  ABOUT: '/about',
  TERMS: '/terms-of-use',
  PRIVACY: '/privacy-policy',
  FAQS: '/faqs'
};

export const DASHBOARD_BASE_REDIRECT = Urls.PROFILE_PAGE;
export const PARTNER_BASE_REDIRECT = Urls.PARTNER_PROFILE_PAGE;

const INLINE_DETAIL_ROUTES = [
  Urls.INSPECTION_DETAIL,
  Urls.POOL_DETAIL,
  Urls.TENURES_DETAIL
];
type TUrlNames = { [K in keyof typeof Urls]: string };

export const UrlNames: TUrlNames = (
  Object.keys(Urls) as Array<keyof typeof Urls>
).reduce((acc, curr) => {
  acc[curr] = curr;
  return acc;
}, {} as TUrlNames);

const Fallback = () => <Spinner center middle />;

const routes = [
  {
    path: Urls.LANDING_PAGE,
    name: 'Landing',
    render: (props: IProps) => (
      <ErrorBoundary>
        <Suspense fallback={<Fallback />}>
          <pages.LandingPage {...props} />
        </Suspense>
      </ErrorBoundary>
    ),
    exact: true,
    public: true
  },
  {
    path: Urls.ALERTS,
    name: 'Alerts',
    render: (props: IProps) => (
      <ErrorBoundary>
        <Suspense fallback={<Fallback />}>
          <Redirect to={Urls.LISTING_PREFERENCES} {...props} />
        </Suspense>
      </ErrorBoundary>
    ),
    exact: true,
    public: false
  },
  {
    path: Urls.LISTINGS_PAGE,
    name: 'Listings',
    render: (props: IProps) => (
      <ErrorBoundary>
        <Suspense fallback={<Fallback />}>
          <pages.ListingListPage {...props} />
        </Suspense>
      </ErrorBoundary>
    ),
    exact: true,
    public: true
  },
  {
    path: Urls.LISTING_DETAILS_PAGE,
    name: 'Detail',
    render: (props: IListingDetailRouteProps) => (
      <ErrorBoundary>
        <Suspense fallback={<Fallback />}>
          <pages.ListingDetailPage {...props} />
        </Suspense>
      </ErrorBoundary>
    ),
    exact: true,
    public: true
  },
  ...[Urls.ABOUT, Urls.FAQS, Urls.TERMS, Urls.PRIVACY].map((url) => ({
    path: url,
    exact: true,
    public: true,
    name: url.split('/').pop()!,
    render: (props: IProps) => (
      <ErrorBoundary>
        <Suspense fallback={<Fallback />}>
          <pages.StaticPages {...props} />
        </Suspense>
      </ErrorBoundary>
    )
  })),
  {
    path: Urls.DASHBOARD_PAGES,
    exact: false,
    name: 'Dashboard',
    render: (props: IDashboardProps) => (
      <ErrorBoundary>
        <Suspense fallback={<Fallback />}>
          <pages.DashboardPages {...props} />
        </Suspense>
      </ErrorBoundary>
    ),
    routes: [
      // {
      //   name: 'Services',
      //   icon: 'nc-basket',
      //   path: Urls.SERVICES,
      //   render: (props: IProps) => (
      //     <Suspense fallback={<Fallback />}>
      //       <ErrorBoundary>
      //         <pages.PreferencesPage {...props} />
      //       </ErrorBoundary>
      //     </Suspense>
      //   ),
      //   exact: true
      // },
      {
        path: '',
        name: 'Account',
        icon: 'nc-single-02',
        routes: [
          {
            path: Urls.PROFILE_PAGE,
            name: 'Profile',
            render: (props: IProps) => (
              <ErrorBoundary>
                <Suspense fallback={<Fallback />}>
                  <pages.ProfilePage {...props} />
                </Suspense>
              </ErrorBoundary>
            ),
            exact: true
          },
          {
            path: Urls.VERIFICATION_PAGE,
            name: 'Verification',
            render: (props: IProps) => (
              <ErrorBoundary>
                <Suspense fallback={<Fallback />}>
                  <pages.VerificationPage {...props} />
                </Suspense>
              </ErrorBoundary>
            ),
            exact: true
          },
          {
            path: Urls.GUARANTOR_PAGE,
            name: 'Guarantor',
            render: (props: IProps) => (
              <ErrorBoundary>
                <Suspense fallback={<Fallback />}>
                  <pages.GuarantorPage {...props} />
                </Suspense>
              </ErrorBoundary>
            ),
            exact: true
          },
          {
            path: Urls.KYC_PAGE,
            name: 'KYC',
            render: (props: IProps) => (
              <ErrorBoundary>
                <Suspense fallback={<Fallback />}>
                  <pages.KycPage {...props} />
                </Suspense>
              </ErrorBoundary>
            ),
            exact: true
          },
          {
            name: 'Change Password',
            path: Urls.CHANGE_PASSWORD,
            isExternal: true
          }
        ]
      },
      {
        path: '',
        name: 'Preferences',
        icon: 'nc-settings',
        routes: [
          {
            path: Urls.FLATMATE_PREFERENCES,
            render: (props: IProps) => (
              <ErrorBoundary>
                <Suspense fallback={<Fallback />}>
                  <pages.PreferencesPage {...props} />
                </Suspense>
              </ErrorBoundary>
            ),
            exact: true,
            name: 'Flatmates'
          },
          {
            path: Urls.LISTING_PREFERENCES,
            render: (props: IProps) => (
              <ErrorBoundary>
                <Suspense fallback={<Fallback />}>
                  <pages.PreferencesPage {...props} />
                </Suspense>
              </ErrorBoundary>
            ),
            exact: true,
            name: 'Listings (Alerts)'
          }
          // {
          //   path: Urls.TOPIC_PREFERENCES,
          //   render: (props: IProps) => (
          //     <Suspense fallback={<Fallback />}>
          //       <ErrorBoundary>
          //         <pages.PreferencesPage {...props} />
          //       </ErrorBoundary>
          //     </Suspense>
          //   ),
          //   exact: true,
          //   name: 'Topics'
          // }
        ]
      },
      {
        path: '',
        routes: [Urls.WAITLISTS, Urls.BOOKMARKS, Urls.INTERESTS].map((url) => ({
          path: url,
          exact: true,
          name: url.split('/').pop()!,
          render: (props: IProps) => (
            <ErrorBoundary>
              <Suspense fallback={<Fallback />}>
                <pages.ListsPage {...props} />
              </Suspense>
            </ErrorBoundary>
          )
        })),
        name: 'Saved',
        icon: 'nc-book-bookmark'
      },
      {
        path: '',
        exact: false,
        routes: [
          Urls.PAYMENTS,
          Urls.INSPECTION_DETAIL,
          Urls.INSPECTIONS,
          Urls.POOLS,
          Urls.POOL_DETAIL,
          Urls.TENURES,
          Urls.TENURES_DETAIL
        ].map((url) => {
          const isInlineDetail = INLINE_DETAIL_ROUTES.includes(url);
          return {
            path: url,
            exact: true,
            // exact: !isInlineDetail,
            name: isInlineDetail ? '' : url.split('/').pop()!,
            render: (props: IProps) => (
              <ErrorBoundary>
                <Suspense fallback={<Fallback />}>
                  <pages.HistoryPage {...props} />
                </Suspense>
              </ErrorBoundary>
            )
          };
        }),
        name: 'History',
        icon: 'nc-tile-56'
      },
      {
        path: Urls.PARTNER_PROFILE_PAGE,
        exact: true,
        name: 'Switch To Partner Dashboard',
        icon: 'nc-settings'
      }
    ]
  },
  {
    path: Urls.PARTNER_PAGES,
    exact: false,
    name: 'Partner',
    render: (props: IDashboardProps) => (
      <ErrorBoundary>
        <Suspense fallback={<Fallback />}>
          <pages.PartnerPages {...props} />
        </Suspense>
      </ErrorBoundary>
    ),
    routes: [
      {
        path: '',
        routes: [
          {
            path: Urls.PARTNER_PROFILE_PAGE,
            name: 'Profile',
            render: (props: IProps) => (
              <ErrorBoundary>
                <Suspense fallback={<Fallback />}>
                  <pages.PartnerProfile {...props} />
                </Suspense>
              </ErrorBoundary>
            ),
            exact: true
          }
        ],
        name: 'Account',
        icon: 'nc-single-02'
      },
      {
        path: '',
        routes: [
          Urls.PARTNER_LISTINGS_PAGE,
          Urls.PARTNER_TENURES_PAGE,
          Urls.PARTNER_INSPECTIONS_PAGE,
          Urls.PARTNER_PAYMENTS_PAGE
        ].map((url) => ({
          path: url,
          exact: true,
          name: url.split('/').pop()!,
          render: (props: IProps) => (
            <ErrorBoundary>
              <Suspense fallback={<Fallback />}>
                <pages.PartnerHistoryPages {...props} />
              </Suspense>
            </ErrorBoundary>
          )
        })),
        name: 'History',
        icon: 'nc-tile-56'
      },
      {
        path: Urls.DASHBOARD_PAGES,
        exact: true,
        name: 'Switch To User Dashboard',
        icon: 'nc-settings'
      }
    ]
  }
];

export default routes;
