import { useHistory } from 'react-router-dom';
import qs, { IParseOptions } from 'qs';
import FuzzySet from 'fuzzyset';

import { toSnakeCase, camelize } from '../../utils';
import { Urls } from '../../routes';
import { TSearchKeys, TSearchParams, searchFields } from './types';

const format = 'RFC1738';

export const searchParamsToQueryString = (obj: TSearchParams) =>
  qs.stringify(toSnakeCase(obj), {
    // strictNullHandling: true,
    skipNulls: true,
    arrayFormat: 'repeat',
    format
  });

export const queryStringToObject = (
  s: string,
  options?: IParseOptions
): TSearchParams =>
  qs.parse(s, {
    // qs.parse(decodeURI(s), {
    ignoreQueryPrefix: true,
    allowDots: false,
    depth: 1,
    parameterLimit: 30,
    decoder: (str, _, charset, type) => {
      if (type === 'key') {
        const camelized = camelize(str) as TSearchKeys;
        return searchFields.includes(camelized) ? camelized : '';
      }

      // Adapted from: https://github.com/ljharb/qs/issues/91#issuecomment-491391000

      const strWithoutPlus = str.replace(/\+/g, ' ');
      if (charset === 'iso-8859-1') {
        // unescape never throws, no try...catch needed:
        return strWithoutPlus.replace(/%[0-9a-f]{2}/gi, unescape);
      }

      if (/^(\d+|\d*\.\d+)$/.test(str)) {
        return parseFloat(str);
      }

      type Bool = 'true' | 'false';

      const keywords: Record<Bool, boolean> = {
        true: true,
        false: false
        // null: null,
        // undefined,
      };
      if (str in keywords) {
        return keywords[str as Bool];
      }

      // utf-8
      try {
        return decodeURIComponent(strWithoutPlus);
      } catch (e) {
        return strWithoutPlus;
      }
    }
  });

const localStorageKey = 'csFilters';

export const getSearchUrlPathFromState = (searchState: TSearchParams) =>
  `${Urls.LISTINGS_PAGE}?${searchParamsToQueryString(searchState)}`;

export const updateSearchUrl = (
  history: ReturnType<typeof useHistory>,
  searchState: TSearchParams
) => {
  history.push({
    pathname: Urls.LISTINGS_PAGE,
    search: searchParamsToQueryString(searchState)
  });
  localStorage.setItem(localStorageKey, JSON.stringify(searchState));
};

// Ideally should be imported from google map, but doesn't work
type GeoCodeResponse = {
  address_components: {
    long_name: string;
    short_name: string;
    types: string[];
  }[];
  formatted_address: string;
};

export const fuzzyMatchAddress = (
  geoData: GeoCodeResponse[],
  address: string
) => {
  const fuzzy = FuzzySet();
  geoData.forEach((t) => {
    t.address_components.forEach((x) => {
      fuzzy.add(x.short_name);
      fuzzy.add(x.long_name);
    });
    fuzzy.add(t.formatted_address);
  });

  const matches = fuzzy.get(address);
  if (matches) {
    matches.sort((a, b) => b[0] - a[0]);
    return matches?.[0][0];
  }
};
