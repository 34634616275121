import { IAPIPaginationResponse } from '../../types';

export type TSortOptionKeys = 'lowest' | 'highest' | 'nearest';
// export type TSortOptionKeys = 'lowest' | 'highest' | 'nearest' | 'furthest';

type TSearchStringKeys =
  | 'location'
  /**
   * @deprecated use filterType instead
   */
  | 'listingType'
  | 'filterType'
  | 'order'
  | 'placeId'
  | 'ref';
type TSearchNumberKeys = 'page' | 'bathrooms' | 'bedrooms';
type TSearchBooleanKeys = 'availability' | 'serviced';
type TSearchMultipleKeys = 'features';
export type TSearchRangeKeys = 'amountMin' | 'amountMax';

export type TSearchKeys =
  | TSearchStringKeys
  | TSearchBooleanKeys
  | TSearchNumberKeys
  | TSearchMultipleKeys
  | TSearchRangeKeys;

export type TSearchComputedKeys = 'coord';

export type TSearchParams = Partial<
  Record<TSearchStringKeys, string> & { order: TSortOptionKeys } & Record<
      TSearchNumberKeys,
      number
    > &
    Record<TSearchBooleanKeys, boolean> &
    Record<TSearchMultipleKeys, string[] | string> &
    Record<TSearchComputedKeys, string> &
    Record<TSearchRangeKeys, number>
>;

export type TPhoto = { url: string; title: string };

export type TListingSnippet = {
  id: number;
  reference: string;
  slug: string;
  title: string;
  amount: string;
  updatedAt: string;
  amountDisplay: string;
  isPs: boolean;
  isShortLet: boolean;
  estateType?: string;
  allowsParty?: boolean;
  numRooms: number;
  listingTypeFacet: string;
  location: {
    coords: number[];
    address: string;
  };
  description?: string;
  photos: TPhoto[];
  availability: 'Pooled' | 'Unavailable' | 'Available';
  listingType:
    | 'sale'
    | 'rent'
    | 'roommate'
    | 'shared'
    | 'short let'
    | 'office'
    | 'studio'
    | 'self-contained'
    | 'mini-flat';
} & (
  | {
      acceptRoommate: false;
    }
  | {
      acceptRoommate: true;
      roommateAmount: string;
      roommateAmountDisplay: string;
    }
);
export type TListing = TListingSnippet & {
  cautionFee: string;
  serviceCharge: string;
  photos: TPhoto[];
  numBathrooms: number;
  kitchens: number;
  canAddToWaitlist: boolean;
  canAddToInterest: boolean;
};

export interface IListingsAPIResponse
  extends IAPIPaginationResponse<TListing> {}

export interface IAPIFilterResponse {
  features: { name: string; id: number }[];
  filterTypes: string[];
  sortOptions: { [key in TSortOptionKeys]: string };
  maxFeatures: number;
}

export const searchFields: TSearchKeys[] = [
  'location',
  'ref',
  'listingType',
  'availability',
  'bathrooms',
  'bedrooms',
  'features',
  'page',
  'placeId',
  'amountMin',
  'amountMax',
  'order'
];

export type TViewMode = 'list' | 'grid';
