import React from 'react';
import { Dropdown } from 'react-bootstrap';
import { useIsMutating } from 'react-query';
import { useLocation, Link } from 'react-router-dom';
import LogoIcon from '../../assets/Icon_Green_Cribstack.png';
import LogoFullColor from '../../assets/Logo_Full_colour_Cribstack.png';
import LogoFullWhite from '../../assets/Logo_White_Cribstack.png';

import Spinner from '../spinner';
import { useAuthState } from '../../features/user';
import { Urls } from '../../routes';

function AuthedDropdownItems() {
  return (
    <>
      <li className="p-2">
        <a href={Urls.DASHBOARD_PAGES}>Dashboard</a>
      </li>
      {/* <li className="p-2">
        <a href={Urls.SERVICES}>Services</a>
      </li> */}
      <li className="p-2">
        <a href={Urls.PROFILE_PAGE}>Profile</a>
      </li>
      <li className="p-2">
        <a href={Urls.LOGOUT}>Logout</a>
      </li>
    </>
  );
}

function Hamburger({ user }: { user: ReturnType<typeof useAuthState> }) {
  return (
    <Dropdown>
      <Dropdown.Toggle
        bsPrefix="dropdown" // needed to remove caret
        variant="secondary"
        id="header-menu-toggler"
        className="green"
      >
        <i className={`la ${user ? 'la-user' : 'la-reorder'}`} />
      </Dropdown.Toggle>

      <Dropdown.Menu className="pl-3">
        {user ? (
          <AuthedDropdownItems />
        ) : (
          <>
            <li className="p-3">
              <a href={Urls.LOGIN}>Login</a>
            </li>
            <li className="p-3">
              <a href={Urls.SIGNUP}>Register</a>
            </li>
          </>
        )}
      </Dropdown.Menu>
    </Dropdown>
  );
}

const Header = React.memo(function Header({
  mode
}: {
  mode: 'light' | 'dark';
}) {
  const user = useAuthState();
  // const isFetching = useIsFetching()
  const mutationCount = useIsMutating();

  return (
    <>
      {mutationCount > 0 && <Spinner float />}
      <div className="mainmenu-wrapper">
        <div
          className={`menu-area menu1 ${
            mode === 'light' ? 'menu--light' : 'menu--dark'
          }`}
        >
          <div className="top-menu-area">
            <div className="container-fluid">
              <div className="row">
                <div className="col-lg-12">
                  <div className="menu-fullwidth py-lg-2">
                    <div className="logo-wrapper order-lg-0 order-sm-1">
                      <div className="logo logo-top">
                        <Link to={Urls.LANDING_PAGE}>
                          <img
                            src={LogoIcon}
                            alt="logo"
                            width="20px"
                            className="img-fluid d-md-none"
                          />
                          <img
                            src={
                              mode === 'light' ? LogoFullWhite : LogoFullColor
                            }
                            alt="logo"
                            width="150px"
                            className="img-fluid d-none d-md-block"
                          />
                        </Link>
                      </div>
                    </div>
                    {/* ends: .logo-wrapper */}
                    <div className="menu-right order-lg-2 order-sm-2">
                      {false && (
                        <div className="search-wrapper d-flex">
                          <div className="nav_right_module search_module">
                            <span className="icon-left" id="basic-addon9">
                              <i className="la la-search" />
                            </span>
                            <div className="search_area">
                              <form action="/">
                                <div className="input-group input-group-light">
                                  <input
                                    type="text"
                                    className="form-control search_field top-search-field"
                                    placeholder="What are you looking for?"
                                    autoComplete="off"
                                  />
                                </div>
                              </form>
                            </div>
                          </div>
                          <div className="search-categories">
                            <ul className="list-unstyled">
                              <li>
                                <a href="/">
                                  <span className="la la-glass bg-danger" />{' '}
                                  Food &amp; Drinks
                                </a>
                              </li>
                              <li>
                                <a href="/">
                                  <span className="la la-cutlery bg-primary" />{' '}
                                  Restaurants
                                </a>
                              </li>
                              <li>
                                <a href="/">
                                  <span className="la la-map-marker bg-success" />{' '}
                                  Places
                                </a>
                              </li>
                              <li>
                                <a href="/">
                                  <span className="la la-shopping-cart bg-secondary" />{' '}
                                  Shopping &amp; Store
                                </a>
                              </li>
                              <li>
                                <a href="/">
                                  <span className="la la-bed bg-info" /> Hotels
                                </a>
                              </li>
                              <li>
                                <a href="/">
                                  <span className="la la-bank bg-warning" /> Art
                                  &amp; History
                                </a>
                              </li>
                            </ul>
                          </div>
                        </div>
                      )}
                      {/* ends: .search-wrapper */}
                      {/* start .author-area */}
                      <div className="author-area">
                        <div className="author__access_area">
                          <ul className="d-flex list-unstyled align-items-center font-weight-bold">
                            {false && (
                              <li>
                                <a
                                  href="add-listing.html"
                                  className="btn btn-xs btn-gradient btn-gradient-two"
                                >
                                  <span className="la la-plus" /> Add Listing
                                </a>
                              </li>
                            )}
                            {user ? (
                              <li className="dropdown has_dropdown">
                                <a
                                  className="dropdown-toggle access-link"
                                  href={Urls.DASHBOARD_PAGES}
                                  id="drop1"
                                  onClick={(e) => e.preventDefault()}
                                >
                                  Account
                                </a>
                                <ul className="dropdown-menu pl-3">
                                  <AuthedDropdownItems />
                                </ul>
                              </li>
                            ) : (
                              <li>
                                <a href={Urls.LOGIN} className="access-link">
                                  Login
                                </a>
                                <span>or</span>
                                <a href={Urls.SIGNUP} className="access-link">
                                  Register
                                </a>
                              </li>
                            )}
                          </ul>
                        </div>
                      </div>
                      {/* end .author-area */}
                      <div className="offcanvas-menu d-none">
                        <Hamburger user={user} />
                      </div>
                      {/* ends: .offcanvas-menu */}
                    </div>
                    {/* ends: .menu-right */}
                  </div>
                </div>
              </div>
              {/* end /.row */}
            </div>
            {/* end /.container */}
          </div>
          {/* end  */}
        </div>
      </div>
    </>
  );
});

export const LandingHeader = () => <Header mode="light" />;

export default function HeaderWrapper() {
  const { pathname } = useLocation();
  const isLandingPage = pathname === Urls.LANDING_PAGE;
  const isDashboard = pathname.includes(Urls.DASHBOARD_PAGES);
  const isPartner = pathname.includes(Urls.PARTNER_PAGES);

  if (isLandingPage || isDashboard || isPartner) return null;

  return <Header mode="dark" />;
}
