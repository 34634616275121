import classNames from 'classnames';
import { useState, useEffect, useRef } from 'react';
import { ButtonProps as ReactBSButtonProps } from 'react-bootstrap/';

import Spinner from '../spinner';
import { useIsMounted } from '../../utils/hooks';

type ButtonProps = React.HTMLAttributes<HTMLElement> & {
  bsStyle?: string;
  children: React.ReactNode;
  disabled?: boolean;
  icon?: React.ReactNode;
  loading?: boolean;
  spinnerColor?: string;
  spinnerSize?: React.ComponentProps<typeof Spinner>['size'];
  onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  variant?: ReactBSButtonProps['variant'] | 'green';
  spinnerDelay?: number;
};

export default function ButtonLoader({
  bsStyle,
  children,
  disabled = false,
  icon,
  loading = false,
  spinnerColor = '#000',
  spinnerSize = 'sm',
  variant = 'secondary',
  className,
  onClick,
  spinnerDelay = 1500,
  ...rest
}: ButtonProps) {
  const buttonDisabled = disabled || loading;
  const btnRef = useRef<HTMLButtonElement | null>(null);

  const isMounted = useIsMounted();
  const [showSpinner, setShowSpinner] = useState(false);

  useEffect(() => {
    const timerId = setTimeout(() => {
      if (isMounted()) setShowSpinner(true);
    }, spinnerDelay);

    return () => clearTimeout(timerId);
  }, [spinnerDelay, isMounted, showSpinner]);

  return (
    <button
      disabled={buttonDisabled}
      {...rest}
      type="submit"
      className={classNames(`btn btn-${variant}`, {
        [className ?? '']: !!className,
        'd-flex justify-content-center align-items-center':
          loading && showSpinner
      })}
      ref={btnRef}
      onClick={(e) => {
        if (onClick) {
          e.preventDefault();
          setShowSpinner(false);
          onClick(e);
        }
      }}
      style={
        loading && showSpinner
          ? {
              width: btnRef.current?.offsetWidth,
              height: btnRef.current?.offsetHeight
            }
          : {}
      }
    >
      {loading && showSpinner ? (
        <Spinner size={spinnerSize} color="green" />
      ) : (
        children
      )}
    </button>
  );
}
