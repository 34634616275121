import { format, formatDistanceToNowStrict } from 'date-fns';
import cloneDeep from 'lodash.clonedeep'; // TODO: remove this later
import isObject from 'lodash.isplainobject';
import isEmpty from 'lodash.isempty';
import { Urls } from '../routes';
import toaster from '../components/toast';
import { TListingDetailsUrlParams } from './../routes/index';
import { TListing } from './../features/search/types';
import analytics from './analytics';
import config from './config';

// polyfill Array.isArray
if (!Array.isArray) {
  // @ts-ignore
  Array.isArray = function (arg: any) {
    return Object.prototype.toString.call(arg) === '[object Array]';
  };
}

export { isObject };
export { isEmpty };

type IObject = Record<string, any>;

export const toSnakeCase = (x: IObject) => {
  const cloned = cloneDeep(x);
  const convert = (obj: IObject) => {
    Object.keys(obj).forEach((key) => {
      let keyName = key;
      const parts = key.split(/(?=[A-Z])/);
      if (parts.length > 1) {
        keyName = parts.join('_').toLowerCase();
        // assign value to snake-cased key and delete camelCased key
        delete Object.assign(obj, { [keyName]: obj[key] })[key];
      }

      if (isObject(obj[keyName])) return convert(obj[keyName]);
      if (Array.isArray(obj[keyName]))
        return obj[keyName].map((k: any) => convert(k));
    });
    return obj;
  };
  return convert(cloned);
};

export const camelize = (x: string) =>
  x.replace(/([_][a-z])/gi, ($1) => {
    return $1.toUpperCase().replace('_', '');
  });

export const toCamelCase = (x: IObject): IObject => {
  if (isObject(x)) {
    const n: IObject = {};

    Object.keys(x).forEach((k) => {
      const camelized = camelize(k);
      n[camelized] = toCamelCase(x[k]);
    });

    return n;
  } else if (Array.isArray(x)) {
    return x.map((i) => {
      return toCamelCase(i);
    });
  }
  return x;
};

export const changeBrowserUrl = (
  url: string,
  state?: object,
  replace = false,
  title = ''
) => {
  const historyAction = replace ? 'replaceState' : 'pushState';
  window.history[historyAction](state, title, url);
};

export const generateUrl = (
  path: string,
  params: Record<string, string> = {}
): string => {
  const matchedParams = path.match(/\/:(\w+)/g);
  if (!matchedParams) return path;

  return matchedParams.reduce((acc, current) => {
    if (acc.indexOf(current) > -1) {
      const match = current.match(/\w+/g);
      if (!match?.length) return '';
      const { 0: text } = match;

      const replacement = params[text] ? `/${params[text]}` : '';
      acc = acc.replace(current, replacement);
    }
    return acc;
  }, path);
};

export const generateListingDetailUrl = (listing: TListingDetailsUrlParams) =>
  generateUrl(Urls.LISTING_DETAILS_PAGE, {
    id: String(listing.id),
    slug: listing.slug,
    listingTypeFacet: listing.listingTypeFacet
  });

export const formatFullDateTime = (dateTime: string | Date) =>
  format(new Date(dateTime), 'E, MMM do h:mma');

export const formatShortDate = (dateTime: string | Date) =>
  format(new Date(dateTime), 'MMM do');

export const formatTimeToText = (time: string) => {
  const answer = formatDistanceToNowStrict(new Date(time));
  return `${answer.trim()} ago`;
};

export const formatAmount = (
  amount: number,
  decimalPlaces = 0,
  compact = false
) =>
  new Intl.NumberFormat('en-NG', {
    style: 'currency',
    currency: 'NGN',
    maximumFractionDigits: decimalPlaces,
    notation: compact ? 'compact' : 'standard',
    compactDisplay: compact ? 'short' : 'long',
    minimumFractionDigits: 0 // looks like some browsers throw randomly. Hopefully setting this should fix it: https://stackoverflow.com/questions/41045270/range-error-with-tolocalestring-with-maximumnumber-of-digits-0 and https://github.com/andyearnshaw/Intl.js/issues/123
  }).format(amount);
// export { default as hello } from './texts'

export const pluralize = (num: number, word: string, suffix = 's') =>
  `${num} ${word}${num !== 1 ? suffix : ''}`;

export const compactAmount = (num: number): string => {
  if (num > 999 && num < 1000000) {
    return (num / 1000).toFixed(1).replace(/\.0$/, '') + 'K';
  } else if (num > 1000000) {
    return (num / 1000000).toFixed(1).replace(/\.0$/, '') + 'M';
  } else {
    return String(num);
  }
};

export const LOGIN_EVENT = 'showLogin';

export const showLoginModal = () =>
  window.dispatchEvent(new CustomEvent(LOGIN_EVENT));

export const redirectToAuth = (isSigup = false) => {
  const isDev = process.env.NODE_ENV === 'development';
  const useLocalProxy = process.env.REACT_APP_USE_LOCAL_PROXY;

  const currentUrl =
    isDev && !useLocalProxy
      ? window.location.href
      : window.location.href.replace(window.location.origin, '');
  window.location.assign(
    `${isSigup ? Urls.SIGNUP : Urls.LOGIN}?next=${currentUrl}`
  );
};

export const parseUserFromWindow = async () => {
  return new Promise(async (resolve) => {
    if (process.env.NODE_ENV === 'development') {
      window.__csData = {
        user: null
      };
      let user;
      const hash = window.location.hash;
      const match = hash.match(/(user)=.*$/);
      if (match) {
        // redirected from a login
        const userString = atob(match[0].replace('user=', ''));
        user = toCamelCase(JSON.parse(userString)) as TUser;
        localStorage.setItem('csUser', JSON.stringify(user));
        window.location.hash = '';
      } else {
        user = localStorage.getItem('csUser');

        if (user) {
          const isLocalhostDev = document.cookie.match(/sessionid=\w+/);
          try {
            if (isLocalhostDev) {
              user = JSON.parse(user);
            } else {
              const res = await fetch(`${config.baseUrl}/api/debug/`, {
                credentials: 'include'
              });
              if (res.status === 200) {
                user = JSON.parse(user);
              } else {
                user = null;
              }
            }
          } catch (e) {
            user = null;
          }
        }
      }

      if (user) {
        if (window.__csData) window.__csData.user = user;
        else
          window.__csData = {
            user
          };
      } else {
        localStorage.removeItem('csUser');
      }
    }

    if (window.__csData.user) {
      window.__csData.user = toCamelCase(window.__csData.user) as TUser;
      const { id, gender, lastName, email } = window.__csData.user;
      setTimeout(() => {
        analytics.identify(id, {
          email,
          gender,
          lastName
        });
      }, 200);
    } else {
      // setTimeout(() => {
      //   analytics.reset();
      // }, 200);
    }
    resolve(void 0);
  });
};

type TSizes = 'xs' | 'sm' | 'md' | 'o' | 'lg';

export const getResizableMediaPath = (url: string, size: TSizes = 'o') =>
  url.startsWith(config.mediaUploadsBaseUrl)
    ? url.split('.com').join(`.com/${size}`)
    : url;

export const getListingPhotos = (
  photos: TListing['photos'],
  size: TSizes
): TListing['photos'] => {
  if (!photos.length) {
    // this should never really happen (unless via admin preview), just to be safe, show a fallback.
    return [{ url: config.defaultListingImage, title: 'Image not uploaded' }];
  }
  return photos.map((x) => ({
    ...x,
    url: getResizableMediaPath(x.url, size)
  }));
};

export const successMessageAndReload = (message: string, reloadSeconds = 5) => {
  toaster.success(
    `${message}. This page will refresh in ${reloadSeconds} seconds.`
  );
  setTimeout(() => {
    window.location.reload();
  }, reloadSeconds * 1000);
};

const checkIsIOS = () =>
  /iPad|iPhone|iPod/.test(navigator.userAgent) && !('MSStream' in window);

export const addMaximumScaleToMetaViewportIOS = () => {
  // iPhone zooms in on input field focus because our font is currently less than 16px. Sadly, it doesn't zoom
  // back out after focus. There are a bunch of solutions, but this seems to be the quickest and most suitable one for now.
  // More info here: https://stackoverflow.com/a/57527009 and https://www.warrenchandler.com/2019/04/02/stop-iphones-from-zooming-in-on-form-fields/
  if (!checkIsIOS()) return;

  const el = document.querySelector('meta[name=viewport]');

  if (el !== null) {
    let content = el.getAttribute('content');
    if (!content) return;

    const re = /maximum-scale=[0-9\\.]+/g;

    if (re.test(content)) {
      content = content.replace(re, 'maximum-scale=1.0');
    } else {
      content = [content, 'maximum-scale=1.0'].join(', ');
    }

    el.setAttribute('content', content);
  }
};
