import React from 'react';
import { Switch, Route, useLocation } from 'react-router-dom';
import { QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import Header from './components/header';
import Footer from './components/footer';

import routes from './routes';
import ErrorBoundary from './components/errorBoundary';
import LoginModal from './components/loginModal';
import { reactQueryClient } from './utils/config';
import { useAuthState, UserProvider } from './features/user';
import { MapProvider } from './components/map/hooks';
import { SearchStateProvider } from './features/search/hooks';
import { redirectToAuth } from './utils';
import config from './utils/config';
import NotFound from './components/notFound';

function Views() {
  /**
   * Need its own component so that it can be placed under the userprovider
   */
  const user = useAuthState();
  return (
    <Switch>
      {routes.map((route) =>
        route.routes ? (
          <Route
            key={route.path}
            {...route}
            render={(props) =>
              route.public || user ? (
                <route.render {...props} routes={route.routes} />
              ) : (
                void redirectToAuth()
              )
            }
          />
        ) : (
          //@ts-ignore TODO: !! fix
          <Route key={route.path} {...route} />
        )
      )}
      <Route component={NotFound} />
    </Switch>
  );
}

function Banner() {
  const { search } = useLocation();

  return (
    <ErrorBoundary>
      {search.includes(config.previewQueryParam) && (
        <div className="global-banner sticky-top font-weight-bold text-center">
          <div className="alert alert-info py-4">PREVIEW MODE</div>
        </div>
      )}
    </ErrorBoundary>
  );
}

export default function App() {
  return (
    <QueryClientProvider client={reactQueryClient}>
      <ReactQueryDevtools />
      <UserProvider>
        {/* TODO: map technically isn't needed on the dashboard, so we might wanna exclude the map provider  */}
        <div id="App" className="d-flex flex-column min-vh-100">
          <ErrorBoundary>
            <Banner />
          </ErrorBoundary>
          <ErrorBoundary>
            <Header />
            <LoginModal />

            <MapProvider>
              <SearchStateProvider>
                <Views />
              </SearchStateProvider>
            </MapProvider>
            <ErrorBoundary>
              <Footer />
            </ErrorBoundary>
          </ErrorBoundary>
        </div>
      </UserProvider>
    </QueryClientProvider>
  );
}
