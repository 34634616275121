import { useState, useEffect } from 'react';
import { useIsMounted } from '../../utils/hooks';
import smallGreenSpinner from './small-green.gif';
import smallWhiteSpinner from './small-white.gif';
import bigGreenSpinner from './big-green.gif';

// TODO: this seems like it's being fetched all the time

export default function Spinner({
  size = 'sm',
  color = 'green',
  style,
  center = false,
  middle = false,
  float,
  delay = 1000
}: {
  size?: 'sm' | 'md' | 'lg';
  color?: 'white' | 'green';
  style?: any;
  center?: boolean;
  middle?: boolean;
  float?: boolean;
  delay?: number;
}) {
  const isMounted = useIsMounted();
  const [show, setShow] = useState(false);

  useEffect(() => {
    const timerId = setTimeout(() => {
      if (isMounted()) setShow(true);
    }, delay);

    return () => clearTimeout(timerId);
  }, [delay, isMounted]);

  if (!show) return null;

  const spinner = {
    sm: smallGreenSpinner,
    md: bigGreenSpinner,
    lg: smallWhiteSpinner
  };

  if (float)
    return (
      <img
        src={smallGreenSpinner}
        alt="global spinner"
        style={{ position: 'fixed', right: 30, top: 30, zIndex: 999999 }}
      />
    );

  const img = <img src={spinner[size]} alt="spinner" style={style} />;

  if (center || middle) {
    return (
      <div
        className="text-center d-flex justify-content-center align-items-center"
        // className={classNames('text-center', {
        //   'd-flex justify-content-center align-items-center': middle
        // })}
        style={{ flex: 1 }}
      >
        {img}
      </div>
    );
  }
  return img;
}
