import { QueryClient } from 'react-query';
import toaster from '../components/toast';
import APIError, { InAppError, reportError } from './errors';

interface IError extends Error {
  status: number;
}

const errorHandler = (err: unknown) => {
  const error = err as IError;
  if (error.status === 410) {
    // redirect intended
    return;
  }

  let messages: string[] = [];
  if (error.message?.match(/failed to fetch/i)) {
    messages = ['Check your connection and try again'];
  } else if (error instanceof APIError) {
    messages = error.messages;
  } else if (error instanceof InAppError) {
    messages = [error.message];
  } else {
    reportError(error);
    messages = ['An unexpected error occured'];
  }

  for (let message of messages) {
    toaster.error(message);
  }
};

export const reactQueryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry(failureCount, error) {
        if ((error as IError).status === 404) return false;
        // we use 410 to signal a redirect
        else if ((error as IError).status === 410) return false;
        else if (failureCount < 2) return true;
        else return false;
      },
      onError: errorHandler
    },
    mutations: {
      onError: errorHandler
    }
  }
});

const config = {
  minPasswordLength: 6,
  googlePlacesAPIKey: process.env.REACT_APP_GOOGLE_PLACES_API_KEY,
  colors: {
    primaryGreen: '#1ba496',
    secondaryGreen: '#126960'
  },
  breakPoints: {
    sm: 576,
    md: 768,
    lg: 992,
    xl: 1200
  },
  maxFeaturesFilter: 3,
  baseUrl: process.env.REACT_APP_BASE_URL || '',
  flutterwaveKey: process.env.REACT_APP_FLUTTERWAVE_KEY || '',
  paystackKey: process.env.REACT_APP_PAYSTACK_KEY || '',
  contactNumber: '+2348130515451',
  twitterHandle: 'cribstackHQ',
  sentryDSN: process.env.REACT_APP_SENTRY_DSN,
  prodEnvironment: process.env.REACT_APP_PROD_ENVIRONMENT,
  defaultListingImage: process.env.REACT_APP_DEFAULT_LISTING_IMAGE || '',
  mediaUploadsBaseUrl: process.env.REACT_APP_RESIZABLE_MEDIA_BASE_URL || '',
  previewQueryParam: 'preview',
  poolRefundDays: 14,
  searchStateStorageKey: 'csSearchState',
  bedroomOptions: [1, 2, 3, 4, 5],
  recombeeListingDb: process.env.REACT_APP_RECOMBEE_LISTING_DB,
  recombeePublicKey: process.env.REACT_APP_RECOMBEE_PUBLIC_KEY
} as const;

// simple static checks.
const requiredKeys = [
  config.googlePlacesAPIKey,
  config.flutterwaveKey,
  config.paystackKey,
  config.contactNumber,
  config.sentryDSN,
  config.mediaUploadsBaseUrl,
  config.recombeeListingDb,
  config.recombeePublicKey
];
requiredKeys.forEach((key) => {
  if (!key) {
    throw new Error(`${key} Key required`);
  }
});

export default config;
