import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import { CaptureConsole as CaptureConsoleIntegration } from '@sentry/integrations';
import App from './App';
import { store } from './app/store';
import * as serviceWorker from './serviceWorker';

import 'unfetch/polyfill';
import 'bootstrap/dist/css/bootstrap.min.css';
import './style.css';
import './index.scss';
import 'line-awesome/dist/line-awesome/css/line-awesome.min.css';

import { parseUserFromWindow, addMaximumScaleToMetaViewportIOS } from './utils';
import config from './utils/config';

(async function () {
  // //@ts-ignore
  // window.__csData = { user: null };
  await parseUserFromWindow();
  addMaximumScaleToMetaViewportIOS();

  if (process.env.NODE_ENV === 'production') {
    const { GIT_BRANCH, GIT_COMMIT, npm_package_version } = process.env;

    Sentry.init({
      dsn: config.sentryDSN,
      integrations: [
        new Integrations.BrowserTracing(),
        new CaptureConsoleIntegration({
          levels: ['error']
        })
      ],
      release: GIT_BRANCH ? `${GIT_BRANCH}:${GIT_COMMIT}` : npm_package_version,
      environment: config.prodEnvironment ?? 'production',

      tracesSampleRate: 0.6,
      // tracesSampler(samplingContext) {
      //   // https://stackoverflow.com/questions/69047420/webpack-code-splitting-chunkloaderror-loading-chunk-x-failed-but-the-chunk-e
      // },

      // Config below is adapted from: https://gist.github.com/impressiver/5092952, mentioned in the sentry docs: https://docs.sentry.io/clients/javascript/tips/

      // Will cause a deprecation warning, but the demise of `ignoreErrors` is still under discussion.
      // See: https://github.com/getsentry/raven-js/issues/73
      ignoreErrors: [
        // Random plugins/extensions
        'top.GLOBALS',
        // See: http://blog.errorception.com/2012/03/tale-of-unfindable-js-error.html
        'originalCreateNotification',
        'canvas.contentDocument',
        'MyApp_RemoveAllHighlights',
        'http://tt.epicplay.com',
        "Can't find variable: ZiteReader",
        'jigsaw is not defined',
        'ComboSearch is not defined',
        'http://loading.retry.widdit.com/',
        'atomicFindClose',
        // Facebook borked
        'fb_xd_fragment',
        // ISP "optimizing" proxy - `Cache-Control: no-transform` seems to reduce this. (thanks @acdha)
        // See http://stackoverflow.com/questions/4113268/how-to-stop-javascript-injection-from-vodafone-proxy
        'bmi_SafeAddOnload',
        'EBCallBackMessageReceived',
        // See http://toolbar.conduit.com/Developer/HtmlAndGadget/Methods/JSInjection.aspx
        'conduitPage',
        // Generic error code from errors outside the security sandbox
        // You can delete this if using raven.js > 1.0, which ignores these automatically.
        'Script error.',

        // custom
        'ResizeObserver loop limit exceeded',

        // Cribstack specific
        'APIError',
        'InAppError',

        // segment
        'Error sending segment performance metrics',

        // TODO: // find a proper fix
        'ChunkLoadError',
        'Loading CSS chunk',

        // browsers blocking this script
        'Failed to load Analytics.js'
      ],
      denyUrls: [
        // Facebook flakiness
        /graph\.facebook\.com/i,
        // Facebook blocked
        /connect\.facebook\.net\/en_US\/all\.js/i,
        // Woopra flakiness
        /eatdifferent\.com\.woopra-ns\.com/i,
        /static\.woopra\.com\/js\/woopra\.js/i,
        // Chrome extensions
        /extensions\//i,
        /^chrome:\/\//i,
        // Other plugins
        /127\.0\.0\.1:4001\/isrunning/i, // Cacaoweb
        /webappstoolbarba\.texthelp\.com\//i,
        /metrics\.itunes\.apple\.com\.edgesuite\.net\//i
      ]
    });

    if (window.__csData.user) {
      Sentry.setUser({ id: window.__csData.user.id });
    }
  }

  const container = document.getElementById('root') as HTMLElement;

  const root = createRoot(container);

  root.render(
    <React.StrictMode>
      <Router>
        <Provider store={store}>
          <App />
        </Provider>
      </Router>
    </React.StrictMode>
  );

  // TODO: If you want your app to work offline and load faster, you can change
  // unregister() to register() below. Note this comes with some pitfalls.
  // Learn more about service workers: https://bit.ly/CRA-PWA
  serviceWorker.unregister();
})();
