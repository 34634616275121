import * as Sentry from '@sentry/react';
import { isObject } from '.';

const NOT_FOUND_MSG = 'Not found.';
const OPERATION_NOT_FOUND_MSG = 'The requested operation does not exist';
const RESOURCE_NOT_FOUND_MSG = 'The requested resource was not found';
const SERVER_ERROR_MSG = 'An unexpected error occured';
const DEFAULT_ERROR_MSG = 'Please try later';

type CustomError = {
  status: number;
  messages: string[];
  data?: any;
};

// https://stackoverflow.com/questions/41102060/typescript-extending-error-class
export default class APIError extends Error implements CustomError {
  messages: string[] = [];
  status;
  data: any;

  constructor(status: number, data: any) {
    super(); // 'Error' breaks prototype chain here

    this.name = 'APIError';

    this.status = status;
    this.data = data;

    this.assignErrorMessage();

    // restore prototype chain
    const actualProto = new.target.prototype;

    if (Object.setPrototypeOf) {
      Object.setPrototypeOf(this, actualProto);
    } else {
      // @ts-ignore
      this.__proto__ = actualProto;
    }
  }

  private assignErrorMessage = () => {
    let message = DEFAULT_ERROR_MSG;
    let messages = [];
    if (this.status === 404) {
      if (!this.data.message) message = OPERATION_NOT_FOUND_MSG;

      message =
        this.data.message === NOT_FOUND_MSG
          ? RESOURCE_NOT_FOUND_MSG
          : OPERATION_NOT_FOUND_MSG;
    } else if (this.status >= 500) {
      message = SERVER_ERROR_MSG;
    } else if (this.data.non_field_errors) {
      messages = this.data.non_field_errors as any[];
    } else if (this.status === 400) {
      // if (isObject(this._data)) {
      //   messages = Object.keys(this._data).reduce(
      //     (previous: string[], current: string) => {
      //       const texts = this._data[current] as string[]
      //       for (let text of texts) {
      //         previous.push(`${current}: ${text}`)
      //       }
      //       return previous
      //     },
      //     []
      //   )
      // }
      message = 'There was an error in your submission' ?? this.data.message;

      // this is experimental - ideally 400 errors can appear for any validation reason, but considering we're
      // doing enough frontend validation, I'm curious to see what could cause these errors, so I'm sending to sentry
      reportError(
        new Error('API400Error'),
        JSON.stringify(this.data.message ?? {})
      );
    } else if (this.status === 410 && this.data.redirect) {
      // this is what we're using to signal a redirect
      window.location.replace(this.data.redirect);
    } else {
      message = this.data.message;
    }

    if (!messages.length) {
      messages = [message ?? DEFAULT_ERROR_MSG];
    }
    this.message = messages[0];
    this.messages = messages;
  };
}

const isProd = process.env.NODE_ENV === 'production';

export const reportError = (error: Error, stackInfo?: string) => {
  if (isProd) {
    let _error = error;
    if (error.message && isObject(error)) {
      // captureException throws if we don't pass an error
      _error = new Error(error.message);
    }
    if (stackInfo) {
      _error.stack = stackInfo;
    }
    Sentry.captureException(_error, { contexts: { react: { stackInfo } } });
  } else {
    // eslint-disable-next-line no-console
    console.error(error);
  }
};

export class InAppError extends Error {
  message = '';
  constructor(message: string) {
    super(); // 'Error' breaks prototype chain here

    this.message = message;

    // restore prototype chain
    const actualProto = new.target.prototype;

    if (Object.setPrototypeOf) {
      Object.setPrototypeOf(this, actualProto);
    } else {
      // @ts-ignore
      this.__proto__ = actualProto;
    }
  }
}
