import { lazy } from 'react';

export const LandingPage = lazy(
  () => import('../pages/landingPage' /* webpackChunkName: 'landing' */)
);

export const ListingListPage = lazy(
  () =>
    import(
      '../pages/listingsPage' /* webpackChunkName: 'list', webpackPrefetch: true */
    )
);

export const ListingDetailPage = lazy(
  () =>
    import(
      '../pages/listingDetailPage' /* webpackChunkName: 'detail', webpackPrefetch: true */
    )
);

export const DashboardPages = lazy(
  () => import('../pages/dashboard' /* webpackChunkName: 'backend' */)
);

export const PreferencesPage = lazy(
  () =>
    import(
      '../pages/dashboard/preferences' /* webpackChunkName: 'preferences' */
    )
);

export const ProfilePage = lazy(
  () =>
    import(
      '../pages/dashboard/account/profilePage' /* webpackChunkName: 'profile' */
    )
);

export const VerificationPage = lazy(
  () =>
    import(
      '../pages/dashboard/account/verificationPage' /* webpackChunkName: 'verification' */
    )
);

export const GuarantorPage = lazy(
  () =>
    import(
      '../pages/dashboard/account/guarantorPage' /* webpackChunkName: 'guarantor' */
    )
);

export const KycPage = lazy(
  () =>
    import('../pages/dashboard/account/kycPage' /* webpackChunkName: 'kyc' */)
);

export const PartnerPages = lazy(
  () => import('../pages/partner' /* webpackChunkName: '' */)
);

export const PartnerProfile = lazy(
  () =>
    import('../pages/partner/account/profilePage' /* webpackChunkName: '' */)
);

export const PartnerHistoryPages = lazy(
  () => import('../pages/partner/history' /* webpackChunkName: '' */)
);

export const SecurityPage = lazy(
  () =>
    import(
      '../pages/dashboard/account/securityPage' /* webpackChunkName: 'security' */
    )
);

export const ListsPage = lazy(
  () => import('../pages/dashboard/saved' /* webpackChunkName: 'saved' */)
);

export const HistoryPage = lazy(
  () => import('../pages/dashboard/history' /* webpackChunkName: 'history' */)
);

export const StaticPages = lazy(
  () => import('../pages/staticPage' /* webpackChunkName: 'static' */)
);
