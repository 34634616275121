import React, { useEffect, useState } from 'react';
import { render } from 'react-dom';
import Toast from 'react-bootstrap/Toast';
import classNames from 'classnames';

type TToast = {
  type: 'success' | 'warning' | 'error' | 'info';
  message: string;
  title?: string;
  noClose?: boolean;
  delay?: number;
};

type TCustomEvent = CustomEvent & { detail: TToast };

const toastEventName = 'add-toast';
const toastContainerID = 'toast-container';

function ToastContainer(props: any) {
  const [toasts, setToasts] = useState<TToast[]>([]);
  useEffect(() => {
    const container = document.getElementById(toastContainerID);
    if (!container) return;

    function addToast(e: TCustomEvent) {
      setToasts((prev) => [...prev, e.detail]);
    }

    // @ts-ignore
    container.addEventListener(toastEventName, addToast);

    // @ts-ignore
    return () => container.removeEventListener(toastEventName, addToast);
  }, []);

  return (
    <div
      style={{
        position: 'fixed',
        top: '.5rem',
        right: '.5rem',
        zIndex: 9999
      }}
    >
      <div>
        {toasts.map(({ message, title, type, noClose, delay }, idx) => (
          <Toast
            onClose={() => {
              setToasts((prev) => prev.filter((x, i) => i !== idx));
            }}
            key={idx}
            show
            delay={delay ?? 10000}
            autohide
            className={classNames({
              // 'bg-info': type === 'info',
              'bg-success': type === 'success',
              'bg-warning': type === 'warning',
              'bg-danger': type === 'error'
            })}
          >
            {title && (
              <Toast.Header closeButton={!noClose}>
                <strong className="mr-auto">{title}</strong>
              </Toast.Header>
            )}
            <Toast.Body>
              {!title && !noClose && (
                <button
                  type="button"
                  className="close pl-2"
                  aria-label="Close"
                  onClick={() =>
                    setToasts((prev) => prev.filter((x, i) => i !== idx))
                  }
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              )}
              <span className="font-weight-bold">{message}</span>
            </Toast.Body>
          </Toast>
        ))}
      </div>
    </div>
  );
}

function Toaster() {
  const containerDomNode = document.createElement('div');
  containerDomNode.setAttribute('id', toastContainerID);
  document.body.appendChild(containerDomNode);
  render(<ToastContainer />, containerDomNode);

  return {
    info: (
      message: string,
      title?: string,
      noClose?: boolean,
      delay?: number
    ) =>
      containerDomNode.dispatchEvent(
        new CustomEvent(toastEventName, {
          detail: { message, type: 'info', title, noClose, delay }
        })
      ),
    success: (
      message: string,
      title?: string,
      noClose?: boolean,
      delay?: number
    ) =>
      containerDomNode.dispatchEvent(
        new CustomEvent(toastEventName, {
          detail: { message, type: 'success', title, noClose, delay }
        })
      ),
    warning: (
      message: string,
      title?: string,
      noClose?: boolean,
      delay?: number
    ) =>
      containerDomNode.dispatchEvent(
        new CustomEvent(toastEventName, {
          detail: { message, type: 'warning', title, noClose, delay }
        })
      ),
    error: (
      message: string,
      title?: string,
      noClose?: boolean,
      delay?: number
    ) =>
      containerDomNode.dispatchEvent(
        new CustomEvent(toastEventName, {
          detail: { message, type: 'error', title, noClose, delay }
        })
      )
  };
}

const toaster = Object.freeze(Toaster());

export default toaster;
