import { useState, useEffect } from 'react';
import { LOGIN_EVENT, redirectToAuth } from '../../utils';
import Modal from '../modal';

export default function LoginModal(props: any) {
  const [show, setShow] = useState(false);

  useEffect(() => {
    function showLoginModal() {
      setShow(true);
    }

    window.addEventListener(LOGIN_EVENT, showLoginModal);

    return () => window.removeEventListener(LOGIN_EVENT, showLoginModal);
  }, []);

  return (
    <Modal
      size="sm"
      backdrop="static"
      className="login-modal"
      bodyClassName="pt-0"
      show={show}
      onHide={() => setShow(false)}
      title="Login Required"
    >
      <div className="p-3 text-center">
        <button
          className="btn btn-block green"
          onClick={() => redirectToAuth()}
        >
          Login
        </button>

        <p className="my-4 font-weight-bold">OR</p>

        <button
          className="btn btn-block btn-secondary"
          onClick={() => redirectToAuth(true)}
        >
          Signup
        </button>
      </div>
    </Modal>
  );
}
