import React, { useContext, useMemo } from 'react';
import { useQuery } from 'react-query';
import { createContext, useCallback, useState } from 'react';
import { useHistory } from 'react-router';
import APIError from '../../utils/errors';
import analytics, { TRACK_EVENTS } from '../../utils/analytics';
import { IListingsAPIResponse, TSearchParams } from './types';
import { getListingFilters, getListings } from './api';
import { queryStringToObject, updateSearchUrl } from './utils';

export const LISTING_SEARCH_QUERY_KEY = 'listings';
const LISTINGS_FILTER_QUERY_KEY = 'listings-filters';

export const useListingSearch = (searchState: TSearchParams) => {
  return useQuery<IListingsAPIResponse, APIError>(
    [LISTING_SEARCH_QUERY_KEY, searchState],
    () => getListings(searchState),
    {
      keepPreviousData: true,
      staleTime: 900000 // 15 minutes
    }
  );
};

export const useGetListingFilters = () =>
  useQuery(LISTINGS_FILTER_QUERY_KEY, getListingFilters, {
    staleTime: 7200000
  });

type TSearchStateContext = {
  state: TSearchParams;
  updateState: (
    state: TSearchParams,
    source: string,
    changeUrl?: boolean
  ) => void;
};

const searchStateContext = createContext<TSearchStateContext | undefined>(
  undefined
);

export const SearchStateProvider = ({
  children
}: {
  children: React.ReactNode;
}) => {
  const [state, setState] = useState<TSearchParams>(() => {
    // TODO: complete implementing this - default read from localStorage
    // try {
    //   const csFilters = JSON.parse(
    //     localStorage.getItem(localStorageKey) || '{}'
    //   )
    //   state.location = csFilters.location
    //   state.placeId = csFilters.placeId
    //   state.listingType = csFilters.listingType
    //   state.bedrooms = csFilters.bedrooms
    // } catch (e) {}
    return window.location.search
      ? queryStringToObject(window.location.search)
      : {};
  });
  const history = useHistory();
  const updateState = useCallback(
    function (newState: TSearchParams, source: string, changeUrl = true) {
      setState(newState);
      if (changeUrl) {
        updateSearchUrl(history, newState);
      }
      setTimeout(() => {
        analytics.track(TRACK_EVENTS.LISTING_FILTER, {
          ...newState,
          source
        });
      }, 500);
    },
    [history]
  );
  const contextValue = useMemo(
    () => ({
      state,
      updateState
    }),
    [state, updateState]
  );

  return (
    <searchStateContext.Provider value={contextValue}>
      {children}
    </searchStateContext.Provider>
  );
};

export const useSearchState = () => {
  const context = useContext(searchStateContext);
  if (!context)
    throw new Error('useSearchState must be used within a SearchStateProvider');
  return context;
};
