import { useEffect, useRef } from 'react';
import BSModal from 'react-bootstrap/Modal';
import { ModalProps as BSModalProps } from 'react-bootstrap';

interface IModalProps extends BSModalProps {
  title: string;
  bodyClassName?: string;
  show: boolean;
  size?: BSModalProps['size'];
}

export default function Modal({
  size,
  show,
  onHide,
  title,
  children,
  backdrop,
  className,
  bodyClassName
}: IModalProps) {
  const elements = useRef<{ el: HTMLElement; zIndex: string }[]>([]);

  useEffect(() => {
    // As part of our increasing issues using sticky-top, I've realized that the backdrop
    // of modals have way lower zIndex than whatever has sticky-top. This means that when a
    // modal is shown, whatever element with sticky-top gets shown over the backdrop
    if (show) {
      document
        .querySelectorAll<HTMLDivElement>(
          '.sticky-top, .listing-details-wrapper'
        )
        .forEach((el) => {
          elements.current.push({
            el,
            zIndex: window.getComputedStyle(el).zIndex
          });
          el.style.zIndex = '1';
        });
    } else {
      elements.current.forEach(({ el, zIndex }) => (el.style.zIndex = zIndex));
      elements.current = [];
    }

    return () => {
      elements.current.forEach(({ el, zIndex }) => (el.style.zIndex = zIndex));
      elements.current = [];
    };
  }, [show]);

  return (
    <BSModal
      className={className}
      size={size}
      show={show}
      backdrop={backdrop}
      onHide={onHide}
      aria-labelledby="cribstack-modal"
    >
      <BSModal.Header closeButton>
        <BSModal.Title id="example-bSModal-sizes-title-lg">
          {title}
        </BSModal.Title>
      </BSModal.Header>
      <BSModal.Body className={bodyClassName}>{children}</BSModal.Body>
    </BSModal>
  );
}
