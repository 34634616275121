import { useMutation } from 'react-query';
import toaster from '../../components/toast';
import APIError from '../../utils/errors';
import request, { rawRequest } from '../../utils/request';
import { TUpdatePasswordParams, TUpdateUserParams } from './types';

type TUserUpdateUserParams = Pick<
  TUpdateUserParams,
  'firstName' | 'lastName' | 'otherNames'
>;
type TUserUpdateActualParams = Omit<
  TUpdateUserParams,
  keyof TUserUpdateUserParams
> & { user?: any };

const updateUser = (data: TUpdateUserParams) => {
  const { firstName, otherNames, lastName, ...rest } = data;
  const params: TUserUpdateActualParams = rest;
  params.user = {
    firstName,
    lastName,
    otherNames
  };
  return request.patch<TUser>(`users/${rest.id}/`, params);
};

const sendSMSCode = ({ phone }: { phone: string }) => {
  return request.post<{}>('users/sms', { phone });
};

const uploadFile = ({ file }: { file: File }): Promise<string> => {
  const extension = file.type
    ? file.type.split('/').pop()
    : file.name.split('.').pop();
  return request
    .post<{ url: string; fields: { [key: string]: string } & { key: string } }>(
      'users/avatar',
      { extension }
    )
    .then(({ url, fields }) => {
      return new Promise(async (resolve, reject) => {
        const formData = new FormData();
        for (let fieldKey in fields) {
          formData.append(fieldKey, fields[fieldKey as keyof object]);
        }
        formData.append('file', file);

        try {
          const response = await rawRequest(url, {
            method: 'POST',
            body: formData
          });

          if (response.ok) {
            resolve(`${url}${fields.key}`);
          } else {
            reject({ message: 'An unexpected error occured' });
          }
        } catch (e) {
          reject(e);
        }
      });
    });
};

export const useUploadFile = () =>
  useMutation<string, APIError, { file: File }>(uploadFile);

const updatePassword = (data: TUpdatePasswordParams) =>
  request.post<{}>('/user/change-password', data);

export const useUpdateUser = () => {
  return useMutation<TUser, APIError, TUpdateUserParams>(updateUser, {
    onSuccess: (data, variables) => {
      if (process.env.NODE_ENV === 'development') {
        localStorage.setItem('csUser', JSON.stringify(data));
      }
      toaster.success('Profile updated');
    }
  });
};

export const useSendSMSCode = () =>
  useMutation<{}, APIError, { phone: string }>(sendSMSCode);

export const useUpdatePassword = () =>
  useMutation<{}, APIError, TUpdatePasswordParams>(updatePassword);
