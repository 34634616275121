import { memo, useEffect, useState } from 'react';
import { useMutation } from 'react-query';
import { Link, useLocation } from 'react-router-dom';
import { useAuthState } from '../../features/user';
import { Urls } from '../../routes';
import analytics, { TRACK_EVENTS } from '../../utils/analytics';
import config from '../../utils/config';
import APIError from '../../utils/errors';
import request from '../../utils/request';
import ButtonLoader from '../buttonLoader';
import toaster from '../toast';

const Footer = memo(function Footer() {
  const [email, setEmail] = useState('');
  const handler = (data: { email: string }) => request.post('subscribe/', data);
  const { mutate, error, status } = useMutation<
    any,
    APIError,
    { email: string }
  >(handler);
  const user = useAuthState();

  useEffect(() => {
    if (status === 'success' || status === 'error') {
      if (error?.data.message) {
        toaster.error(error.data.message);
      } else {
        toaster.success('Email subscribed');
        setEmail('');
      }
    }
  }, [error, status]);

  return (
    <footer className="footer-three footer-grey pt-5 mt-auto">
      <div className="footer-top p-bottom-25">
        <div className="container">
          <div className="row">
            <div className="col-lg-4 col-sm-6">
              <div className="widget widget_pages">
                <h2 className="widget-title">Quick Links</h2>
                <ul className="list-unstyled">
                  <li className="page-item">
                    <Link to={Urls.ABOUT}>About Us</Link>
                  </li>
                  <li className="page-item">
                    <Link to={Urls.TERMS}>Terms & Conditions</Link>
                  </li>
                  <li className="page-item">
                    <Link to={Urls.FAQS}>FAQ</Link>
                  </li>
                  <li className="page-item">
                    <Link to={Urls.PRIVACY}>Privacy Policy</Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-4 col-sm-6">
              <div className="widget widget_social">
                <h2 className="widget-title">Connect with Us</h2>
                <ul className="list-unstyled social-list">
                  <li>
                    <a href="mailto:support@cribstack.com">
                      <span className="mail">
                        <i className="la la-envelope" />
                      </span>{' '}
                      Contact Support
                    </a>
                  </li>
                  <li>
                    <a href={`tel:${config.contactNumber}`}>
                      <span className="phone">
                        <i className="la la-phone" />
                      </span>{' '}
                      Call Us
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://twitter.com/cribstackHQ"
                      target="_blank"
                      rel="noopener"
                    >
                      <span className="twitter">
                        <i className="la la-twitter" />
                      </span>{' '}
                      Twitter
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://facebook.com/cribstack"
                      target="_blank"
                      rel="noopener"
                    >
                      <span className="facebook">
                        <i className="la la-facebook-f" />
                      </span>{' '}
                      Facebook
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://instagram.com/cribstack"
                      target="_blank"
                      rel="noopener"
                    >
                      <span className="instagram">
                        <i className="la la-instagram" />
                      </span>{' '}
                      Instagram
                    </a>
                  </li>
                </ul>
              </div>
              {/* ends: .widget */}
            </div>
            {/* ends: .col-lg-3 */}
            <div className="col-lg-4 col-sm-6">
              <div className="widget widget_text">
                <h2 className="widget-title">Subscribe</h2>
                <div className="textwidget">
                  <p>
                    Enter your email to get access to deals. Don't worry, we
                    won't send spam!
                  </p>
                  <form
                    className="form"
                    onSubmit={(event) => {
                      event.preventDefault();
                      mutate(
                        {
                          email
                        },
                        {
                          onSuccess: () => {
                            analytics.track(TRACK_EVENTS.SUBSCRIBE, {
                              email
                            });
                          }
                        }
                      );
                    }}
                  >
                    <fieldset disabled={!!user}>
                      <div className="form-group mb-2">
                        <label htmlFor="subscribe" className="sr-only">
                          Email
                        </label>
                        <input
                          type="email"
                          required
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                          className="form-control"
                          id="subscribe"
                          placeholder="Your Email"
                        />
                      </div>
                      <ButtonLoader
                        className="btn green text-white p-2"
                        loading={status === 'loading'}
                      >
                        Subscribe
                      </ButtonLoader>
                    </fieldset>
                  </form>
                </div>
              </div>
              {/* ends: .widget */}
            </div>
            {/* ends: .col-lg-3 */}
          </div>
        </div>
      </div>
    </footer>
  );
});

export default function FooterWrapper() {
  const { pathname } = useLocation();
  const isDashboard = pathname.includes(Urls.DASHBOARD_PAGES);
  const isPartner = pathname.includes(Urls.PARTNER_PAGES);

  if (isDashboard || isPartner) return null;

  return <Footer />;
}
