import * as React from 'react';
import { useEffect } from 'react';
import { useUpdateUser } from './hooks';
import { TPatchUserData, TUserContext } from './types';

const UserStateContext = React.createContext<TUserContext['user']>(null);
const UserUpdateContext = React.createContext<Omit<
  TUserContext,
  'user'
> | null>(null);

const UserProvider = ({ children }: { children: React.ReactNode }) => {
  const [user, setUser] = React.useState<TUser | null>(window.__csData.user);
  const { mutate: updateUser, isLoading, data, error } = useUpdateUser();
  const patchUser = React.useCallback((data: TPatchUserData) => {
    setUser((prev) => {
      if (prev) return { ...prev, ...data };
      return null;
    });
  }, []);

  const mutateState = React.useMemo(
    () => ({ updateUser, patchUser, isLoading, error }),
    [updateUser, patchUser, isLoading, error]
  );

  useEffect(() => {
    if (data) {
      setUser(data);
    }
  }, [data]);

  useEffect(() => {
    if (process.env.NODE_ENV === 'development') {
      localStorage.setItem('csUser', JSON.stringify(user));
    }
  }, [user]);

  return (
    <UserStateContext.Provider value={user}>
      <UserUpdateContext.Provider value={mutateState}>
        {children}
      </UserUpdateContext.Provider>
    </UserStateContext.Provider>
  );
};

// authState is used app-wide to know if logged in or not
const useAuthState = () => React.useContext(UserStateContext);

// userState is used in the dashboard, where user is always truthy
const useUserState = () => {
  const user = React.useContext(UserStateContext);
  if (!user)
    throw new Error(
      'UserStateContext should only be used in the dashboard: it must be truthy'
    );

  return user;
};

const useUpdateUserState = () => {
  const context = React.useContext(UserUpdateContext);
  if (!context)
    throw new Error('useUpdateUserState must be used within a UserProvider');
  return context;
};

export { UserProvider, useAuthState, useUserState, useUpdateUserState };
